import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const UserList = () => import("@/views/Users/UserList");
const UserDetails = () => import("@/views/Users/UserDetails");
const ServiceRequest = () => import("@/views/ServiceRequest/ServiceRequest");
const ServiceRequestDetails = () =>
  import("@/views/ServiceRequest/ServiceRequestDetails");
const WarrantyRequest = () => import("@/views/WarrantyRequest/WarrantyRequest");
const WarrantyRequestDetails = () =>
  import("@/views/WarrantyRequest/WarrantyRequestDetails");

const GuideAndHelp = () => import("@/views/GuideAndHelp/GuideAndHelp");
const Settings = () => import("@/views/Settings");
const Notifications = () => import("@/views/Notifications");
const ManagerList = () => import("@/views/Manager/ManagerList");
const ManagerAdd = () => import("@/views/Manager/ManagerAdd");
const ManagerDetails = () => import("@/views/Manager/ManagerDetails");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const ResetPassword = () => import("@/views/pages/ResetPassword");
const ForgotPwd = () => import("@/views/pages/ForgotPwd");
const AdminProfile = () => import("@/views/admin/AdminProfile");
const TermsAndConditions = () => import("@/views/pages/TermsAndConditions");
const PrivacyPolicy = () => import("@/views/pages/PrivacyPolicy");
const WarrantyList = () => import("@/views/WarrantyList/WarrantyList.vue");
const CustomerList = () => import("@/views/Customer/CustomerList.vue");
const CustomerDetails = () => import("@/views/Customer/CustomerDetails.vue");
const ShipmentDataList = () => import("@/views/Shipment/ShipmentData.vue");
const NewWarrantyDetails = () =>
  import("@/views/WarrantyList/NewWarrantyDetails.vue");

Vue.use(Router);
const router = new Router({
  mode: process.env.NODE_ENV === "production" ? "history" : "hash",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/login",
      component: TheContainer,
      meta: {
        requiresAuth: false,
      },

      children: [
        {
          path: "/users",
          name: "users",
          component: UserList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/users/details",
          name: "usersdetails",
          component: UserDetails,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/services",
          name: "servicerequest",
          component: ServiceRequest,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/services/details",
          name: "servicerequestdetails",
          component: ServiceRequestDetails,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/machine",
          name: "warrantyrequest",
          component: WarrantyRequest,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/machine/details",
          name: "warrantyrequestdetails",
          component: WarrantyRequestDetails,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/help",
          name: "guideandhelp",
          component: GuideAndHelp,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/settings",
          name: "settings",
          component: Settings,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/notifications",
          name: "notifications",
          component: Notifications,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/profile",
          name: "Profile",
          component: AdminProfile,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/login/resetpwd",
          name: "reset_password",
          component: ResetPassword,
          meta: { requiresAuth: true },
        },
        {
          path: "/managers",
          name: "managerlist",
          component: ManagerList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/managers/add",
          name: "manageradd",
          component: ManagerAdd,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/managers/edit",
          name: "manageredit",
          component: ManagerAdd,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/managers/details",
          name: "managerdetails",
          component: ManagerDetails,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/warranty",
          name: "warrantylist",
          component: WarrantyList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/customer",
          name: "customer",
          component: CustomerList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/customer/details",
          name: "customerDetails",
          component: CustomerDetails,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/shipmentdata",
          name: "shipmentdata",
          component: ShipmentDataList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/warranty/details",
          name: "newWarrantyDetails",
          component: NewWarrantyDetails,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "/terms_of_use",
          name: "terms_of_use",
          component: TermsAndConditions,
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/privacy_policy",
          name: "privacy_policy",
          component: PrivacyPolicy,
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "404",
          name: "Page404",
          component: Page404,
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/login",
          name: "Login",
          component: Login,
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/login/forgotpwd",
          name: "ForgotPwd",
          component: ForgotPwd,
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },
  ];
}

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth == true) {
    if (
      localStorage.getItem("userToken") &&
      localStorage.getItem("userToken") != ""
    ) {
      if (
        (to.path == "/help" ||
          to.path == "/settings" ||
          to.path == "/managers" ||
          to.path == "/managers/add" ||
          to.path == "/shipmentdata" ||
          to.path == "/customer" || 
          to.path == '/warranty' ) &&
        localStorage.getItem("roleID") != 5
      ) {
        next({
          name: "Login",
        });
        return;
      } else {
        next();
        return;
      }
    }
    next({
      name: "Login",
    });
    return;
  } else {
    if (
      to.path == "/login" &&
      localStorage.getItem("userToken") &&
      localStorage.getItem("userToken") != ""
    ) {
      next({
        name: "users",
      });
    } else {
      next();
      return;
    }
  }
});

export default router;
